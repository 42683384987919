@font-face {
  font-family: montserrat;
  src: url('font/Montserrat-VariableFont_wght.ttf');
}

.montserrat{
  font-family: montserrat;
}

.divPara{
  font-family: montserrat;
  font-weight: 350;
}

.navbar{
  font-family: montserrat;
  font-weight: 500;
}

.heading{
  font-family: montserrat;
  font-weight: 700;
}

@media (max-width: 768px) {
  .bg-cover {
    background-size: cover;
    background-position: center;
  }

  .bg-center {
    background-position: center;
  }
}

/* body {
  font-family: montserrat;
} */



/* .App {
  text-align: center;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
